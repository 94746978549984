<template>
  <div>
    <div style="margin: 10px 0 10px 10px">
      <el-row>
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          @click="dialogVisible = true"
          >添加</el-button
        >
      </el-row>
    </div>

    <div class="add_dialog">
      <el-dialog
        :title="`${form.id ? '编辑' : '添加'}菜单信息`"
        :visible.sync="dialogVisible"
        width="600px"
        @close="handleDialogClose"
        :before-close="handleClose"
        center
      >
        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">关键词：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.name_key"
              placeholder="请输入关键词"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">标题：</p>
          <div class="addAdmin_select">
            <el-input v-model="form.title" placeholder="请输入标题" clearable />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">菜单状态：</p>
          <el-switch
            v-model="form.status"
            active-text="开启"
            inactive-text="关闭"
          />
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">上级菜单：</p>

          <div class="addAdmin_select">
            <el-select
              v-model="form.fid"
              filterable
              placeholder="父级"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="(item, i) in selectData"
                :key="i"
                :label="item.title"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">图标：</p>
          <div class="addAdmin_select">
            <el-input v-model="form.icon" placeholder="请输入图标" clearable />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">排序：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.order"
              type="number"
              placeholder="请输入排序"
              clearable
            />
          </div>
        </div>

        <div class="addAdmin">
          <p class="addAdmin_CharacterSet">前端路由地址：</p>
          <div class="addAdmin_select">
            <el-input
              v-model="form.path_name"
              placeholder="请输入前端路由地址"
              clearable
            />
          </div>
        </div>

        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button
            v-loading="isLoading.table"
            type="primary"
            @click="handleDialogConfirm"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <div class="table_style" ref="del_btn">
      <el-table
        ref="multipleTable"
        v-loading="isLoading.table"
        header-align="center"
        border
        class="main-table"
        :tree-props="{ children: 'sub', hasChildren: 'hasChildren' }"
        row-key="id"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" align="center" />
        <el-table-column prop="title" label="标题" align="center" />
        <el-table-column prop="path_name" label="前端路由地址" align="center" />
        <el-table-column
          prop="status"
          align="center"
          label="是否启用"
          width="100"
        >
          <template slot-scope="{ row }">
            <div>
              <el-switch
                v-model="row.status"
                :active-value="1"
                :inactive-value="0"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="图标" align="center" />
        <el-table-column prop="fid" label="上级ID" align="center" />
        <el-table-column prop="order" label="排序" align="center" />
        <el-table-column prop="created_at" label="添加时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.created_at
                ? new Date(row.created_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.updated_at
                ? new Date(row.updated_at * 1000).toLocaleString("zh-Hans-CN")
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="operation"
          label="操作"
          align="center"
          width="300"
        >
          <template slot-scope="{ row, $index }">
            <div>
              <el-button
                type="warning"
                size="small"
                plain
                icon="el-icon-edit-outline"
                style="margin-right: 10px"
                @click.stop="handleEditAuth(row)"
                >编辑</el-button
              >
              <el-popover trigger="click" placement="top">
                <p>
                  你确认要删除<strong>{{ row.title }}</strong
                  >角色组吗?
                </p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="hidePopover()"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="handleDeleteAuth(row.id, $index)"
                    >确认</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  plain
                  icon="el-icon-delete"
                  type="danger"
                  size="small"
                  @click.stop
                  >删除</el-button
                >
              </el-popover>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name_key: "",
        title: "",
        status: true,
        icon: "",
        fid: "",
        order: "",
        path_name: "",
      },
      tableData: [],
      selectData: [],
      isLoading: {
        table: false,
      },
      dialogVisible: false,
    };
  },

  created() {
    this.getTableData();
  },
  methods: {
    //弹窗关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done(_);
        })
        .catch((_) => {
          _;
        });
    },
    //获取到权限列表
    getTableData() {
      this.tableData = [];
      this.$axios
        .get("/a1/rules/tree", {})
        .then((res) => {
          const { data } = res.data;
          this.tableData = data;
          const selectData = [];
          this.createTableData(data, selectData, 0);
          this.selectData = selectData;
        })
        .catch(() => {});
    },
    createTableData(tableData, selectData, index) {
      tableData.forEach((item) => {
        if (item.fid == 0) {
          selectData.push({
            id: item.id,
            title: item.title,
          });
        } else {
          selectData.push({
            id: item.id,
            title: `|--${item.title}`,
          });
        }
        if (item.sub && item.sub.length) {
          index++;
          this.createTableData(item.sub, selectData, index);
        }
      });
    },
    // 深拷贝
    handleEditAuth(row) {
      const formData = JSON.parse(JSON.stringify(row));
      this.form.name_key = formData.name_key;
      this.form.title = formData.title;
      formData.status === 1;
      this.form.icon = formData.icon;
      this.form.fid = formData.fid;
      this.form.order = formData.order;
      this.form.path_name = formData.path_name;
      this.form.id = formData.id;
      this.dialogVisible = true;
    },
    // 编辑跟新增
    handleDialogConfirm() {
      this.isLoading.table = true;
      if (this.form.id) {
        this.$axios
          .put(
            `/a1/rules/${this.form.id}`,
            Object.assign(
              {},
              this.form,
              {
                status: this.form.status ? 1 : 0,
              },
              { order: parseInt(this.form.order) },
              { fid: parseInt(this.form.fid) }
            )
          )
          .then(() => {
            this.$message.success("修改成功");
            this.getTableData();
            this.dialogVisible = false;
          })
          .finally(() => {
            this.isLoading.table = false;
          });
        return;
      }
      this.$axios
        .post(
          "/a1/rules",
          Object.assign(
            {},
            this.form,
            {
              status: this.form.status ? 1 : 0,
            },
            { order: parseInt(this.form.order) },
            { fid: parseInt(this.form.fid) }
          )
        )
        .then(() => {
          this.$message.success("新增成功");
          this.getTableData();
          this.dialogVisible = false;
        })
        .finally(() => {
          this.isLoading.table = false;
        });
    },
    // 清空表格数据
    handleDialogClose() {
      this.form = {
        name_key: "",
        title: "",
        status: true,
        icon: "",
        fid: "",
        order: "",
        path_name: "",
      };
    },
    // 删除
    handleDeleteAuth(id, index) {
      this.isLoading.table = true;
      this.$axios
        .delete(`/a1/rules/${id}`)
        .then(() => {
          this.tableData.splice(index, 1);
          this.$notify({
            title: "成功",
            message: "删除菜单权限",
            type: "success",
          });
        })
        .catch(() => {
          this.$notify.error({
            title: "失败",
            message: "删除菜单权限",
          });
        })
        .finally(() => {
          this.hidePopover();
          this.isLoading.table = false;
          this.getTableData();
        });
    },
    hidePopover() {
      this.$refs.del_btn.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.input_text {
  width: 100px;
  color: #889aa4;
}

.footer {
  margin: 100px;
  text-align: center;
}
.addAdmin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  &_CharacterSet {
    font-size: 15px;
    width: 120px;
    text-align: right;
  }

  &_select {
    flex: 1;
    justify-content: flex-start;
  }
  &_swith {
    text-align: center;
  }
}
.table_style {
  margin-left: 10px;
}
</style>
